import CloseIcon from 'assets/icons/close.svg?react';
import welcomeImage from 'assets/images/onboarding/hub_onboarding_img.jpg';
import IconButton from 'components/clickables/IconButton';
import useAuthorization from 'hooks/useAuthorization';
import useUserPreferences from 'hooks/useUserPreferences';
import { t } from 'i18next';
import { useState } from 'react';
import Joyride, { CallBackProps, Step, TooltipRenderProps } from 'react-joyride';
import styled from 'styled-components';
import { MerButtonStyle } from 'styles/mixins';
import {
    borderRadiusM,
    borderRadiusS,
    colorModalOverlay,
    font,
    spaceM,
    spaceS,
    spaceXs,
    white,
} from 'styles/variables';
import { FEATURE_LIST } from 'types';
import { EBusinessFeature } from 'types/business';
import { showFeature } from 'utils/featureFlag';
import { scrollToTop } from 'utils/scrollToTop';

const ImageContainer = styled.div`
    margin-left: -${spaceS};
    margin-top: -${spaceS};
    margin-right: -${spaceS};
    margin-bottom: ${spaceM};
    border-radius: ${borderRadiusS} ${borderRadiusS} 0 0;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

const CustomTooltipContainer = styled.div`
    width: 25rem;
    background-color: ${white};
    border-radius: ${borderRadiusM};
    padding: ${spaceM};
    position: relative;
    .close-button {
        position: absolute;
        right: ${spaceXs};
    }
    h1 {
        font-family: ${font.body};
        font-size: ${font.size.l};
        font-weight: ${font.weight.semiBold};
        line-height: ${font.lineHeight.l};
        margin-bottom: 4px;
    }
    p {
        margin: 0;
        margin-bottom: ${spaceM};
        font-size: ${font.size.m};
    }
`;

const StyledIconButton = styled(IconButton)`
    position: absolute;
    top: 1.125rem;
    right: 1.125rem;
    width: 1.5rem;
    height: 1.5rem;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    .buttonGroup {
        display: flex;
        gap: 0.75rem;
    }
`;

const StyledButton = styled.button`
    ${MerButtonStyle}
    height: 2.5rem;
`;

export function CustomTooltip(props: TooltipRenderProps): React.ReactNode {
    const { backProps, index, primaryProps, skipProps, step, tooltipProps, size } = props;
    const totalSteps = size - 1;

    return (
        <CustomTooltipContainer {...tooltipProps} data-testid={`onboarding_step_${index}`}>
            {index !== 0 && (
                <StyledIconButton
                    className="tooltip__close"
                    {...skipProps}
                    type="button"
                    data-testid="onboarding_closeButton"
                >
                    <CloseIcon />
                </StyledIconButton>
            )}
            {index === 0 && (
                <ImageContainer>
                    <img src={welcomeImage} alt="text" />
                </ImageContainer>
            )}
            <h1>{step.title}</h1>
            <p>{step.content}</p>
            <ButtonContainer>
                {index === 0 ? (
                    <StyledButton
                        className="btn--text"
                        {...skipProps}
                        type="button"
                        data-testid="onboarding_skipButton"
                    >
                        {skipProps.title}
                    </StyledButton>
                ) : (
                    <span data-testid="onboarding_stepIndicator">
                        {t('tourGuide.stepIndicator', {
                            currentStep: index,
                            totalSteps,
                        })}
                    </span>
                )}
                <div className="buttonGroup">
                    {index > 0 && (
                        <StyledButton
                            className="btn--secondary"
                            {...backProps}
                            type="button"
                            data-testid="onboarding_backButton"
                        >
                            {backProps.title}
                        </StyledButton>
                    )}

                    <StyledButton
                        className="btn--primary"
                        {...primaryProps}
                        type="button"
                        data-testid="onboarding_nextButton"
                    >
                        {index === 0 ? t('tourGuide.start') : primaryProps.title}
                    </StyledButton>
                </div>
            </ButtonContainer>
        </CustomTooltipContainer>
    );
}

function OnboardGuide(): React.ReactNode {
    const [steps] = useState<Step[]>([
        {
            target: 'body',
            title: t('startpage.onboarding.welcome.title'),
            content: t('startpage.onboarding.welcome.description'),
            placement: 'center',
            disableBeacon: true,
        },
        {
            target: '.userMenu',
            title: t('startpage.onboarding.step1.title'),
            content: t('startpage.onboarding.step1.description'),
            disableBeacon: true,
        },
        {
            target: '.navigationMenu_subscription',
            title: t('startpage.onboarding.step2.title'),
            content: t('startpage.onboarding.step2.description'),
            placement: 'right',
            disableBeacon: true,
        },
        {
            target: '.subscriptionKPISection',
            title: t('startpage.onboarding.step3.title'),
            content: t('startpage.onboarding.step3.description'),
            disableBeacon: true,
        },
        {
            target: '.navigationMenu_facility',
            title: t('startpage.onboarding.step4.title'),
            content: t('startpage.onboarding.step4.description'),
            placement: 'right',
            disableBeacon: true,
        },
        {
            target: '.helpSection',
            title: t('startpage.onboarding.step5.title'),
            content: t('startpage.onboarding.step5.description'),
            placement: 'top',
            disableBeacon: true,
        },
    ]);

    const {
        checkAuthorizationFeatureArray,
        error: errorAuthorization,
        isLoading: isLoadingAuthorization,
    } = useAuthorization();

    const { setUserPreferences, userPreferences } = useUserPreferences(true);

    const handleJoyrideCallback = (data: CallBackProps): void => {
        const { status } = data;
        if (status === 'finished' || status === 'skipped') {
            setUserPreferences({ hasSeenOnboardGuide: true });
            scrollToTop();
        }
    };

    const showGuide =
        userPreferences &&
        (userPreferences?.hasSeenOnboardGuide === undefined || userPreferences?.hasSeenOnboardGuide === false);

    const showOnboardingGuide =
        !isLoadingAuthorization &&
        !errorAuthorization &&
        showGuide &&
        showFeature(FEATURE_LIST.SHOW_ONBOARD_GUIDE) &&
        checkAuthorizationFeatureArray([EBusinessFeature.FACILITY_MANAGEMENT_EDIT]) &&
        checkAuthorizationFeatureArray([EBusinessFeature.SUBSCRIPTION_MANAGEMENT_EDIT]);

    return (
        showOnboardingGuide && (
            <Joyride
                callback={handleJoyrideCallback}
                tooltipComponent={CustomTooltip}
                continuous
                steps={steps}
                disableOverlayClose
                scrollToFirstStep
                scrollOffset={70}
                locale={{
                    skip: t('tourGuide.skip'),
                    next: t('tourGuide.next'),
                    back: t('tourGuide.back'),
                    last: t('tourGuide.last'),
                }}
                styles={{
                    options: {
                        zIndex: 10000,
                        overlayColor: colorModalOverlay,
                    },
                }}
            />
        )
    );
}

export default OnboardGuide;
