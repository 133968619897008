export enum PATH {
    STARTPAGE = '/',
    SIGNUP = '/signup',
    MY_ACCOUNT = '/myAccount',

    ERROR = '/error',
    TEMPORARY_ERROR_NO_MENU = '/temporaryError',
    CRITICAL_ERROR_NO_MENU = '/criticalError',
    NO_PERMISSION = '/accessDenied',
    WILDCARD = '/*',

    SUBSCRIPTION_CREATE = '/subscription/create',
    SUBSCRIPTION_OVERVIEW = '/subscription/overview',
    SUBSCRIPTION_ORDERS = '/subscription/orders',
    SUBSCRIPTION_STATISTICS = '/subscription/statistics',
    SUBSCRIPTION_IMPORT = '/subscription/import',

    FACILITY_MANAGEMENT = '/facility/management',
    FACILITY_STATISTICS = '/facility/statistics',

    INVOICES = '/invoices',

    DEPRECATED_SUBSCRIPTION_OVERVIEW = '/subscriptions',
    DEPRECATED_SUBSCRIPTION_CREATE = '/createSubscription',
    DEPRECATED_SUBSCRIPTION_STATISTICS = '/subscriptionStatistics',
    DEPRECATED_SUBSCRIPTION_IMPORT = '/import/subscriptions',
    DEPRECATED_FACILITY_STATISTICS = '/facilityStatistics',
}
