import ExclamationIcon from 'assets/icons/ic_warning_solid.svg?react';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
    borderRadiusM,
    connectorStatusDefaultBackground,
    connectorStatusFaulted,
    contentBoxPaddingSmall,
    font,
} from 'styles/variables';
import { EConnectorStatus } from 'types/facility';

const Container = styled.article`
    position: relative;
    border-radius: ${borderRadiusM};
    padding: ${contentBoxPaddingSmall};
    min-width: 7.75rem;
    width: max-content;
    background-color: ${connectorStatusDefaultBackground};

    &.faulted {
        background-color: ${connectorStatusFaulted};
    }
`;

const Text = styled.p`
    font-weight: ${font.weight.semiBold};
    margin: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    .number {
        line-height: ${font.lineHeight.xl};
        font-family: ${font.bold};
        font-size: ${font.size.xl};
    }
`;

const IconWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    margin: 8px;
`;

export type StatusBoxProps = {
    status: EConnectorStatus;
    amount: number;
    totalNumbersOfConnectors: number;
};

function StatusBox({ status, amount, totalNumbersOfConnectors }: StatusBoxProps): JSX.Element {
    const { t } = useTranslation();
    const statusText = status.toLocaleLowerCase();

    const isFaulted = status === EConnectorStatus.FAULTED && amount > 0;

    const amountOfConnectors = status === EConnectorStatus.AVAILABLE ? `${amount}/${totalNumbersOfConnectors}` : amount;

    return (
        <Container className={isFaulted ? statusText : ''} data-testid={`statusBox_${statusText}`}>
            {isFaulted && (
                <IconWrapper>
                    <ExclamationIcon data-testid="errorIcon" />
                </IconWrapper>
            )}
            <Text>
                <span className="number">{amountOfConnectors}</span>
                <span>{t(`status.${statusText}`)}</span>
            </Text>
        </Container>
    );
}

export default StatusBox;
