import { CombinedCreateSubscriptionType } from 'pages/subscription/CreateSubscriptionPage';
import { FormEventHandler, JSX } from 'react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import { IBulkSubscriptionType } from 'types/subscription';

type FormProps = {
    formMethods:
        | UseFormReturn<FieldValues>
        | UseFormReturn<IBulkSubscriptionType>
        | UseFormReturn<CombinedCreateSubscriptionType>;
    children: React.ReactNode;
    onSubmit?: FormEventHandler<HTMLFormElement>;
    className?: string;
};

function Form({ formMethods, children, onSubmit, className }: FormProps): JSX.Element {
    return (
        <FormProvider {...(formMethods as UseFormReturn<FieldValues>)}>
            <form onSubmit={onSubmit || formMethods.handleSubmit(() => {})} noValidate className={className}>
                {children}
            </form>
        </FormProvider>
    );
}

export default Form;
