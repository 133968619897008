import ActiveSubscriptionsKpi from './ActiveSubscriptionsKpi';
import Co2Kpi from './Co2Kpi';
import DynamicKPISection from './DynamicKPISection';
import { JSX, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { spaceM } from 'styles/variables';

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: ${spaceM};
    gap: ${spaceM};

    @container kpi-container (max-width: 600px) {
        display: flex;
        flex-direction: column;
    }
`;

const KpiContainer = styled.section`
    grid-column: span 3;
    display: flex;
    gap: ${spaceM};
    flex-wrap: wrap;

    > * {
        flex: 1;
        min-width: 12.5rem;
        max-width: 24rem;

        @container kpi-container (max-width: 550px) {
            min-width: 100%;
        }
    }
`;

const KPIPageWrapper = styled.div`
    margin: ${spaceM} 0;
    container-type: inline-size;
    container-name: kpi-container;
`;

export interface KPIPageProps {
    organisationsDropDown?: JSX.Element;
}

function KPIPage({ organisationsDropDown }: KPIPageProps): JSX.Element {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get('searchTerm') || searchParams.get('pageNumber') || searchParams.get('tab')) {
            searchParams.delete('searchTerm');
            searchParams.delete('pageNumber');
            searchParams.delete('tab');
            setSearchParams(searchParams);
        }
    }, [searchParams, setSearchParams]);

    return (
        <KPIPageWrapper>
            {organisationsDropDown}
            <Container>
                <KpiContainer>
                    <ActiveSubscriptionsKpi />
                    <Co2Kpi />
                </KpiContainer>
                <DynamicKPISection />
            </Container>
        </KPIPageWrapper>
    );
}

export default KPIPage;
