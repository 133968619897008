import { DragEvent, JSX, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
    borderColorBlue,
    borderRadiusS,
    errorColor,
    font,
    lighterBlue,
    spaceM,
    transitionDefault,
    white,
} from 'styles/variables';

const DropArea = styled.section`
    position: relative;
    background-color: ${lighterBlue};
    border: 1px dashed ${borderColorBlue};
    border-radius: ${borderRadiusS};
    padding: ${spaceM};
    text-align: center;
`;

const DropOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${borderColorBlue};
    z-index: -1;
    transition:
        z-index ${transitionDefault},
        opacity ${transitionDefault},
        background-color ${transitionDefault},
        color ${transitionDefault};
    opacity: 0;
    font-weight: ${font.weight.semiBold};
    &.drag-active {
        z-index: 1;
        opacity: 1;
    }
    &.invalid-drag {
        color: ${white};
        background-color: ${errorColor};
    }
`;

type DragDropFileProps = {
    children?: React.ReactNode;
    selectedFiles?: (files: File[]) => void;
};

function DragDropFile({ children, selectedFiles }: DragDropFileProps): JSX.Element {
    const { t } = useTranslation();
    const [dragActive, setDragActive] = useState(false);
    const [invalidDrag, setInvalidDrag] = useState(false);

    const handleDrag = useCallback((e: DragEvent<HTMLElement>): void => {
        e.preventDefault();
        e.stopPropagation();
        setInvalidDrag(e.dataTransfer.items.length > 1);
        setDragActive(e.type === 'dragenter' || e.type === 'dragover');
    }, []);

    const handleDrop = useCallback(
        (e: DragEvent<HTMLElement>): void => {
            e.preventDefault();
            e.stopPropagation();
            setDragActive(false);
            if (e.dataTransfer.files?.length) {
                const files = Array.from(e.dataTransfer.files);

                if (selectedFiles) {
                    selectedFiles(files);
                }
            }
        },
        [selectedFiles],
    );

    const enableDragActive = dragActive ? 'drag-active' : '';
    const enableInvalidDrag = invalidDrag ? 'invalid-drag' : '';

    return (
        <DropArea
            data-testid="dropArea"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
        >
            <DropOverlay data-testid="dropOverlay" className={`${enableDragActive} ${enableInvalidDrag}`}>
                {invalidDrag ? t('widget.browseFile.multiple') : t('widget.dragDropFile.dropFile')}
            </DropOverlay>
            {children}
        </DropArea>
    );
}

export default DragDropFile;
