import ConsumedEnergyChart from './ConsumedEnergyChart';
import MostUsedChargers from './MostUsedChargers';
import PartnerRoamingKpi from './PartnerRoamingKpi';
import TimeFrameFilter, { ETimeFrame } from './TimeFrameFilter';
import TotalDistance from './TotalDistance';
import TotalKwhKpi from './TotalKwhKpi';
import TotalKwhPerType from './TotalKwhPerType';
import VariableCost from './VariableCost';
import SubscriptionStatisticsContext from 'contexts/SubscriptionStatisticsContext';
import { getISOWeek, getMonth, getYear } from 'date-fns';
import useKpi from 'hooks/useKpi';
import { JSX, useContext, useState } from 'react';
import styled from 'styled-components';
import { spaceM } from 'styles/variables';

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spaceM};
`;

function DynamicKPISection(): JSX.Element {
    const [timeFrame, setTimeFrame] = useState('');
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [week, setWeek] = useState('');

    const organisationId = useContext(SubscriptionStatisticsContext);
    const isAllSelected = !!organisationId.includes('all');
    const {
        kpiData,
        error,
        isLoading: isLoadingKpi,
    } = useKpi({
        businessId: !isAllSelected ? organisationId : undefined,
        year,
        month,
        week,
    });

    const handleTimeFrameChange = (type: ETimeFrame, date: Date): void => {
        setYear(getYear(date).toString());
        setTimeFrame(type);
        if (type === ETimeFrame.MONTHLY) {
            const adjustedMonth = getMonth(date) + 1; // BFF expect month numbers 1-12, date-fns generate 0-11
            setMonth(adjustedMonth.toString());
            setWeek('');
        } else if (type === ETimeFrame.WEEKLY) {
            setWeek(getISOWeek(date).toString());
            setMonth('');
        } else {
            setWeek('');
            setMonth('');
        }
    };

    return (
        <>
            <TimeFrameFilter onTimeFrameChange={handleTimeFrameChange} hasDataFetchError={!!error} />
            <ConsumedEnergyChart isLoading={isLoadingKpi} data={kpiData} timeFrame={timeFrame} />
            <TotalKwhKpi data={kpiData?.consumedEnergyForSubscriptionTypes} />
            <PartnerRoamingKpi data={kpiData?.consumedEnergyForRoamingPartners} />
            <VariableCost data={kpiData} />
            <MostUsedChargers chargerPoints={kpiData?.usedChargePoints} />
            <FlexContainer>
                <TotalDistance data={kpiData?.drivenKilometers} />
                <TotalKwhPerType data={kpiData?.consumedEnergyForCurrentType} />
            </FlexContainer>
        </>
    );
}

export default DynamicKPISection;
