import { GlobalState } from 'little-state-machine';
import { CreateSubscriptionFormInputType } from 'types/subscription';

export const INITIAL_GLOBAL_STATE: GlobalState = {
    personalisedSubscriptionOrder: [],
    unpersonalisedSubscriptionOrder: [],
    bulkImportSubscriptionOrder: [],
    expandibleMenus: [],
    sideMenuExpanded: true,
    connectorState: [],
    bannerState: {},
};

export const EMPTY_PERSONALIZED_SUBSCRIPTION: CreateSubscriptionFormInputType = {
    id: '',
    type: '',
    unknownContact: false,
    contact: {
        email: '',
        firstName: '',
        lastName: '',
    },
    licencePlate: '',
    reference: '',
    organisation: {
        id: '',
        name: '',
        hasFacilityManagementInAllBusinesses: false,
    },
    deliveryOption: '',
};

export const EMPTY_UNPERSONALIZED_SUBSCRIPTION: CreateSubscriptionFormInputType = {
    id: '',
    type: '',
    unknownContact: true,
    licencePlate: '',
    reference: '',
    organisation: {
        id: '',
        name: '',
        hasFacilityManagementInAllBusinesses: false,
    },
    deliveryOption: '',
};
