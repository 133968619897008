import React, { JSX, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { modalWidthSmall } from 'styles/variables';

type Props = {
    children: ReactNode;
};

export type ModalContextType = {
    showCloseButton: boolean;
    setCloseButtonVisible: (v: boolean) => void;
    isOpen: boolean;
    newModalContent: ReactNode;
    close: () => void;
    open: (component: JSX.Element) => void;
    lockModal: (v: boolean) => void;
    shouldCloseOnEsc: boolean;
    shouldCloseOnOverlayClick: boolean;
    isLocked: boolean;
    width: string;
    height: string;
    setModalWidth: (v: string) => void;
    setModalHeight: (v: string) => void;
};

const ModalContext = React.createContext<ModalContextType>({
    showCloseButton: true,
    setCloseButtonVisible: () => undefined,
    isOpen: false,
    newModalContent: null,
    close: () => undefined,
    open: () => undefined,
    lockModal: () => undefined,
    shouldCloseOnEsc: true,
    shouldCloseOnOverlayClick: true,
    isLocked: false,
    width: modalWidthSmall,
    height: '',
    setModalWidth: () => undefined,
    setModalHeight: () => undefined,
});

export function ModalProvider(props: Props): JSX.Element {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState<JSX.Element>();
    const [isLocked, setIsLocked] = useState(false);
    const [modalWidth, setModalWidth] = useState(modalWidthSmall);
    const [modalHeight, setModalHeight] = useState('');
    const [closeButtonVisible, setCloseButtonVisible] = useState(true);

    const closeModal = useCallback(() => {
        setModalOpen(false);
        setIsLocked(false);
        setModalWidth(modalWidthSmall); // reset modal to default small size
        setModalHeight(''); // reset modal height
        setCloseButtonVisible(true);
    }, [setModalOpen]);

    const openModal = useCallback(
        (newContent: JSX.Element) => {
            setModalOpen(true);
            setModalContent(newContent);
        },
        [setModalOpen, setModalContent],
    );

    const lockModal = useCallback(
        (v: boolean) => {
            setIsLocked(v);
        },
        [setIsLocked],
    );

    const context = useMemo(
        () => ({
            showCloseButton: closeButtonVisible,
            setCloseButtonVisible,
            isOpen: modalOpen,
            close: closeModal,
            open: openModal,
            newModalContent: modalContent,
            shouldCloseOnEsc: true,
            shouldCloseOnOverlayClick: !isLocked,
            lockModal,
            isLocked,
            setModalWidth,
            width: modalWidth,
            height: modalHeight,
            setModalHeight,
        }),
        [
            modalOpen,
            closeModal,
            openModal,
            modalContent,
            isLocked,
            lockModal,
            modalWidth,
            closeButtonVisible,
            modalHeight,
        ],
    );

    return <ModalContext.Provider value={context} {...props} />;
}

export const useModal = (): ModalContextType => useContext(ModalContext);
