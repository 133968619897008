import { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { font } from 'styles/variables';

const Container = styled.p`
    font-weight: ${font.weight.semiBold};
    align-self: center;
    margin: 0;
    color: ${font.color.grey};
    white-space: nowrap;
`;

type TotalCountProps = {
    total: number;
    className?: string;
};

function TotalCount({ total, className }: TotalCountProps): JSX.Element {
    const { t } = useTranslation();
    return (
        <Container className={className}>
            {t('general.total')}: {total}
        </Container>
    );
}
export default TotalCount;
