import CrossIcon from 'assets/icons/cross.svg?react';
import { JSX } from 'react';
import styled from 'styled-components';
import { borderRadiusM, font, lightBlue, lighterBlue, primaryMerBlue, spaceS, spaceXs } from 'styles/variables';

const FilterBar = styled.section`
    display: flex;
    flex-wrap: wrap;
    gap: ${spaceS};
    min-height: 2.5rem;
`;
const Item = styled.div`
    height: 2.5rem;
    background: ${lightBlue};
    border-radius: ${borderRadiusM};
    padding-right: ${spaceXs};
    line-height: ${font.lineHeight.l};
    display: flex;
    align-items: center;
    gap: ${spaceXs};
    color: ${primaryMerBlue};
    button {
        color: inherit;
        background: transparent;
        border: none;
        cursor: pointer;
    }
`;

const ItemText = styled.button`
    padding: ${spaceXs} 0 ${spaceXs} ${spaceS};
    font-size: ${font.size.m};
`;

const CrossButton = styled.button`
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background: ${lighterBlue};
    }
    svg {
        width: 0.75rem;
        height: 0.75rem;
    }
`;
type FilterItem = {
    text: string;
    value: string;
};

type SelectedFilterBarProps = {
    selectedFilters: FilterItem[];
    onClearFilter: (v: string) => void;
    onItemClick: () => void;
    className?: string;
};

function SelectedFilterBar({
    selectedFilters,
    onClearFilter,
    onItemClick,
    className,
}: SelectedFilterBarProps): JSX.Element {
    return (
        <FilterBar className={className} data-testid="selectedFilterBar">
            {selectedFilters.map((item) => (
                <Item key={item.value}>
                    <ItemText onClick={onItemClick} data-testid={`selectedFilterText_${item.value}`}>
                        {item.text}
                    </ItemText>
                    <CrossButton onClick={() => onClearFilter(item.value)} data-testid={`selectedFilter_${item.value}`}>
                        <CrossIcon />
                    </CrossButton>
                </Item>
            ))}
        </FilterBar>
    );
}

export default SelectedFilterBar;
