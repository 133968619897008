import SmallContentBox from './SmallContentBox';
import customerServiceImage from 'assets/images/img_customer_service.jpg';
import { JSX, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spaceXs } from 'styles/variables';
import { BuType } from 'types/businessUnit';
import { getLocalizedShortWeekdays } from 'utils/dateLocalization';

const Link = styled.a`
    display: block;
    width: fit-content;
    text-decoration: none;
    margin-bottom: ${spaceXs};
    word-break: break-all;
`;

const OpeningHours = styled.span`
    display: block;
`;

type CustomerServiceBoxProps = {
    buContent: BuType;
};

function CustomerServiceBox({ buContent }: CustomerServiceBoxProps): JSX.Element {
    const { t } = useTranslation();

    const openingHours = useMemo(() => {
        const weekDays = getLocalizedShortWeekdays();
        const rows: string[] = [];
        buContent.customerService.openingHours.forEach((item) => {
            let hours = '';
            let days = '';

            if (item.dayFrom && item.dayTo) {
                days =
                    item.dayFrom === 'MONDAY' && item.dayTo === 'FRIDAY'
                        ? t('startpage.customerService.weekDays')
                        : `${weekDays[item.dayFrom]} - ${weekDays[item.dayTo]}`;
            } else if (item.dayFrom && !item.dayTo) {
                days = `${weekDays[item.dayFrom]}`;
            }

            if (item.from && item.to) {
                hours = `${item.from} ${t('startpage.customerService.to')} ${item.to}`;
            }

            if (hours && days) {
                rows.push(`${days}  ${hours}`);
            } else if (!hours && days) {
                rows.push(`${days} ${t('startpage.customerService.allHours')}`);
            } else if (!hours && !days) {
                rows.push(`${t('startpage.customerService.twentyFourSeven')}`);
            } else if (hours && !days) {
                rows.push(`${t('startpage.customerService.allDays')} ${hours}`);
            }
        });
        return rows;
    }, [buContent, t]);

    return (
        <SmallContentBox
            dataTestId="customerServiceHelpBox"
            title={t('startpage.customerService.title')}
            text={openingHours.map((item) => (
                <OpeningHours key={item}>{item}</OpeningHours>
            ))}
            image={customerServiceImage}
        >
            <Link href={`tel:${buContent.customerService.phoneNumer}`}>{buContent.customerService.phoneNumer}</Link>
            <Link href={`mailto:${buContent.customerService.email}`}>{buContent.customerService.email}</Link>
        </SmallContentBox>
    );
}

export default CustomerServiceBox;
