import { JSX } from 'react';
import styled from 'styled-components';
import { font, primaryMerBlue, spaceL, spaceS, tabsBorderColor } from 'styles/variables';

const Container = styled.section`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid ${tabsBorderColor};
`;
const Tab = styled.label<{ checked: boolean; size: TabSize }>`
    ${({ size }) =>
        size === 'fill' &&
        `
        flex: 1 1 auto;
    `};
    ${({ size }) =>
        size === 'justify' &&
        `
        flex: 1 0px;
    `};
    text-align: center;
    margin-bottom: -1px;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-color: ${({ checked }) => (checked ? primaryMerBlue : 'transparent')};
    color: ${({ checked }) => (checked ? font.color.default : font.color.grey)};
    padding: ${spaceS} ${spaceL};
    font-size: ${font.size.m};
    font-weight: ${({ checked }) => (checked ? font.weight.bold : font.weight.semiBold)};

    cursor: pointer;
    user-select: none;

    input {
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    &:hover {
        color: ${font.color.default};
    }
`;

export type Tab = {
    text: string;
    value: string;
};

type TabSize = 'fill' | 'justify' | 'normal';

type TabsProps = {
    tabs: Tab[];
    onTabClick: (activeButton: Tab) => void;
    activeTab: Tab;
    size?: TabSize;
    className?: string;
    children?: React.ReactNode;
    onClick?: () => void;
};

function Tabs({ tabs, onTabClick, activeTab, size = 'justify', className, children, onClick }: TabsProps): JSX.Element {
    return (
        <Container role="tablist" className={className} data-testid="tabsContainer">
            {tabs.map((tab) => {
                const selected = tab.value === activeTab.value;
                return (
                    <Tab
                        htmlFor={tab.value}
                        key={tab.value}
                        checked={selected}
                        size={size}
                        role="tab"
                        aria-selected={selected}
                        data-testid={`tab_${tab.value}`}
                    >
                        {tab.text}
                        <input
                            name="radioTabs"
                            type="radio"
                            id={tab.value}
                            checked={selected}
                            onChange={() => onTabClick(tab)}
                            onClick={onClick}
                        />
                    </Tab>
                );
            })}
            {children}
        </Container>
    );
}

export default Tabs;
