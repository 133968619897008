import MenuItem, { NavigationLinkStyle } from './MenuItem';
import { MenuListType } from './NavigationMenu';
import ArrowDown from 'assets/icons/arrow-down-small.svg?react';
import ArrowUp from 'assets/icons/arrow-up-small.svg?react';
import useLocalState from 'hooks/useLocalState';
import { JSX, useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { font, iconSize, linkHoverBackgound, primaryMerBlue, red, spaceXxs, transitionDefault } from 'styles/variables';
import { ETrackingAction, ETrackingEvent } from 'types/tracking';

const MenuButton = styled.button<{ $expanded: boolean }>`
    ${NavigationLinkStyle}
    white-space: nowrap;
    grid-template-columns: ${iconSize} minmax(0, 1fr) 1rem;
    font-family: ${font.body};
    font-weight: ${({ $expanded }) => ($expanded ? font.weight.semiBold : font.weight.regular)};
    background: transparent;
    width: 100%;
    height: 2.75rem;
    font-size: ${font.size.m};
    text-align: left;
    border: 0;

    &.active {
        color: ${primaryMerBlue};
        font-weight: ${font.weight.bold};
        background-color: ${linkHoverBackgound};
    }
`;

const SubMenuContainer = styled.ul<{ $expanded: boolean }>`
    max-height: ${({ $expanded }) => ($expanded ? '400px' : '0')};
    overflow: hidden;
    transition: all ${transitionDefault};
`;

const Title = styled.span`
    display: flex;
    gap: ${spaceXxs};
`;

const Dot = styled.span`
    width: 5px;
    height: 5px;
    background: ${red};
    border-radius: 100%;
`;

type ExpandibleMenuItemProps = {
    item: MenuListType;
};

function ExpandibleMenuItem({ item: { title, name, icon, items, showDot } }: ExpandibleMenuItemProps): JSX.Element {
    const { pathname } = useLocation();
    const {
        actions: { setExpandibleMenu, removeExpandibleMenu },
        state: { expandibleMenus },
    } = useLocalState();

    const expanded = !!expandibleMenus?.find((menu) => menu === name);
    const [hasActiveSubItem, setHasActiveSubItem] = useState<boolean>(false);
    const subEntryActive = !!items.find((menuItem) => pathname.includes(menuItem.path));
    const expandMenu = useCallback(
        (exp: boolean) => {
            if (exp && !expanded) {
                setExpandibleMenu(name);
                ReactGA.event(ETrackingEvent.MENU_ITEM, {
                    action: ETrackingAction.EXPAND,
                    label: name,
                });
            } else if (!exp && expanded) {
                removeExpandibleMenu(name);
                ReactGA.event(ETrackingEvent.MENU_ITEM, {
                    action: ETrackingAction.COLLAPSE,
                    label: name,
                });
            }
        },
        [expanded, setExpandibleMenu, name, removeExpandibleMenu],
    );

    useEffect(() => {
        if (subEntryActive) {
            setExpandibleMenu(name);
        }
        setHasActiveSubItem(subEntryActive);
    }, [setExpandibleMenu, subEntryActive, name]);

    return (
        <>
            <MenuButton
                data-testid={`menu_${name}`}
                type="button"
                $expanded={expanded}
                onClick={() => expandMenu(!expanded)}
                className={hasActiveSubItem && !expanded ? 'active' : undefined}
            >
                {icon}
                <Title>
                    {title}
                    {showDot ? <Dot data-testid={`dot_${name}`} /> : null}
                </Title>
                {expanded ? <ArrowUp data-testid="expandedMenuIcon" /> : <ArrowDown data-testid="collapsedMenuIcon" />}
            </MenuButton>
            <SubMenuContainer $expanded={expanded}>
                {items.map((item) => {
                    return item.visible ? (
                        <li key={item.title}>
                            <MenuItem item={item} />
                        </li>
                    ) : null;
                })}
            </SubMenuContainer>
        </>
    );
}

export default ExpandibleMenuItem;
