export const pageKeys = {
    STARTPAGE: 'startPage',
    CREATE_SUBSCRIPTION: 'createSubscription',
    BULK_IMPORT: 'bulkImport',
    SUBSCRIPTION_OVERVIEW: 'subscriptionOverview',
    SUBSCRIPTION_ORDER: 'subscriptionOrder',
    SUBSCRIPTION_STATISTICS: 'subscriptionStatistics',
    FACILITY_MANAGEMENT: 'facilityManagement',
    FACILITY_STATISTICS: 'facilityStatistics',
    INVOICES: 'invoices',
    MY_ACCOUNT: 'myAccount',
};
