import 'react-day-picker/dist/style.css';
import { DateRangeFormat } from './DateRange';
import { MAX_DATE_RANGE } from 'constants/general';
import { format, parse } from 'date-fns';
import { useCountrySpecificContent } from 'hooks/useCountrySpecificContent';
import useDateValidation from 'hooks/useDateValidation';
import { JSX } from 'react';
import { DayPicker } from 'react-day-picker';
import { black, darkerGrey, font, lightBlue, primaryMerBlue, spaceS, white } from 'styles/variables';
import { getDateLocale } from 'utils/dateLocalization';

const styling = `
    .rdp-root{
        --rdp-accent-color: ${primaryMerBlue};
        --rdp-accent-background-color: ${lightBlue};
        --rdp-font-family: ${font.body};
        --rdp-day-width:36px;
        --rdp-day-height:36px;

        padding:${spaceS};
    }

    .rdp-month_grid{
        border-collapse: separate;
        border-spacing: 0 2px;
    }

    .rdp-weekday{
        text-transform: uppercase;
        font-size: ${font.size.s} !important;
    }


    .rdp-day,
    .rdp-month_caption {
        font-size: ${font.size.m}
    }

    .rdp-range_start{
        border-radius: 50% 0 0 50%;
    }
        
    .rdp-range_end { 
        border-radius: 0 50% 50% 0;
    }


    .rdp-range_middle{
        color: ${primaryMerBlue};
        button:hover {
            border-radius: 100%;
            background: ${primaryMerBlue};
            color: ${white};
        }
    }
    
    .rdp-range_start,
    .rdp-range_end {
        &.rdp-selected {
            .rdp-day_button{
                border: 4px solid ${lightBlue};
            }
        }
    }

    .rdp-today{
        font-weight: ${font.weight.bold};
    }

    .rdp-chevron{
        fill: ${black};
    }

    .rdp-outside{
        color: ${darkerGrey};
    }

    .rdp-day {
        &:hover:not(.rdp-selected):not(.rdp-range_middle):not(.rdp-disabled) {
            button {
                background: white;  
                border-radius: 100%;
                border: 2px solid ${primaryMerBlue};
                color: ${primaryMerBlue};
            }
        }
        &.rdp-disabled button:hover {
            cursor: not-allowed;
        }
        &.rdp-range_middle.rdp-selected.rdp-outside {
            color:${primaryMerBlue};
        }
    }

    .rdp-button_next,.rdp-button_previous{
        &:hover:not(:disabled){
            background: ${white};
            border-radius: 100%;

        }
        &:hover:disabled{
            cursor: not-allowed;
        }       
    }
`;

type DateRangeProps = {
    range: DateRangeFormat;
    setRange: (arg: { from: string; to: string }) => void;
};

function DateRangePicker({ range, setRange }: DateRangeProps): JSX.Element {
    const { dateFormat } = useCountrySpecificContent();
    const { isDateAfterToday, isFromDateAfterToDate, isValidDate } = useDateValidation();

    return (
        <>
            <style>{styling}</style>
            <DayPicker
                locale={getDateLocale()}
                mode="range"
                showOutsideDays
                ISOWeek
                selected={{
                    from:
                        isValidDate(range.from) &&
                        !isDateAfterToday(range.from) &&
                        !isFromDateAfterToDate(range.from, range.to)
                            ? parse(range.from, dateFormat, new Date())
                            : undefined,
                    to:
                        isValidDate(range.to) &&
                        !isDateAfterToday(range.to) &&
                        !isFromDateAfterToDate(range.from, range.to)
                            ? parse(range.to, dateFormat, new Date())
                            : undefined,
                }}
                onSelect={(date) => {
                    setRange({
                        from: date?.from ? format(date.from, dateFormat) : '',
                        to: date?.to ? format(date.to, dateFormat) : '',
                    });
                }}
                endMonth={new Date()}
                disabled={[{ after: new Date() }]}
                defaultMonth={isValidDate(range.from) ? parse(range.from, dateFormat, new Date()) : undefined}
                max={MAX_DATE_RANGE}
            />
        </>
    );
}
export default DateRangePicker;
